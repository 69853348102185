<template>
    <div class="h-48px flex items-center fixed w-full px-24px z-99" :style="{background,top:`${top}px`}">
      <div @click="gohome" v-if="isback" >
        <img  class="w-28px h-28px bg-o" src="~@/assets/new/left-icon.png" alt="" srcset="">
      </div>
      <div class="absolute left-70px right-70px text-center text-17px font-medium">{{ title }}</div>
      
        <div class="absolute right-24px">
          <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
export default {
  props:{
    top:{
      type: Number,
      default: 44,
    },
    title:{
      type: String,
      default: '',
    },
    background:{
      type: String,
      default: '',
    },
    isback:{
      type:Boolean,
      default:true
    }
  },
  methods: {
    gohome() {
        this.$router.back()
    },
  },
};
</script>

<style scoped lang="scss">

</style>
