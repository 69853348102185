<template>
  <div class="user-page min-h-full relative">
    <Headers :title="$t('head.route3')"></Headers>
    <div class="community pt-92px px-20px">
      <div class="text-white py-10px">{{ $t("head.referral") }}</div>
      <div class="bg-[#ffffff] bg-opacity-40 p-10px rounded-10px mb-10px flex justify-between" v-for="(item, index) in zhituilist" :key="index">
        <!-- <div class="left">
          {{ $t("head.hashPower") }}:
          {{ (balance[index].balanceOf / 1e18).toFixed(3) }}
        </div> -->
        <div class="text-white">
          {{ $t("head.address") }}: {{ item.address.slice(0, 4) }}...{{
            item.address.slice(38)
          }}
        </div>
        <div class="text-white">
          {{item.balance}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from "../components/headers.vue";
import mixin from "../mixins/index";
import { MultiCall } from "eth-multicall";
export default {
  components: {
    Headers,
  },
  data() {
    return {
      zhituilist: [],
      balance: [],
    };
  },
  mixins: [mixin],
  watch: {
    "global.account"() {
     
      this.IPHInvitation_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphInvitation_abi,
        this.pool.iphInvitation
      );
      this.POOL5_contract = new this.global.web3.eth.Contract(
        this.poolabi.pool_abi5,
        this.pool.pool5
      );

      this.IPHInvitation_contract.methods.getMyChilders(this.global.account).call().then(res=>{
        this.zhituilist=res.map(e=>{
          return {
            address:e,
            balance:0
          }
        })

        this.zhituilist.map(e=>{
          this.POOL5_contract.methods.getUsdtLevel(e.address).call().then(res=>{
            e.balance=res
          })
        })


      })
    },
  },
  methods: {},
};
</script>


<style lang="scss" scoped>
.user-page {
  background-image: url('~@/assets/new/home-bg.png');
  background-repeat: no-repeat;
  background-size: 775px 775px;
  min-height: 100%;
}
.box{
  backdrop-filter: saturate(180%) blur(2px); //毛玻璃效果
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.21),
    rgba(255, 255, 255, 0.1)
  );
  border-radius: 16px;
}
</style>