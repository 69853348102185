<template>
  <div class="page-bg-gradient min-h-full">
    <Headers></Headers>
    <div class="announcement pt-130px">
      <div class="content text-white bg-[#ffffff1a] p-10px rounded-10px mb-10px" v-for="(item, index) in content" :key="index">
        <div class="left">
          <img src="../assets/iamge/gonggao_1.png" alt="" />
        </div>
        <div class="middle flex-1">
          <div class="box1">{{ item.id }}.{{ item.title }}</div>
<!--          <div class="box2"> </div>-->
<!--          <div class="box3">{{ item.createTime | formatDate }}</div>-->
        </div>
        <div class="right">
          <img src="../assets/iamge/gonggao_2.png" @click="gogo(item.id)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from "../components/headers.vue";
import mixin from "../mixins/index";
export default {
  data() {
    return {
      content: [],
    };
  },
  mixins: [mixin],
  mounted() {
    this.$axios.get(`${this.gonggao}/${this.$i18n.locale}`).then((res) => {
      // console.log(res.code);
      this.content = res;
      // console.log(this.content);
    });
  },
  methods:{
    gogo(n){
      this.$router.push({
        path:"/gonggao",
        query:{
          id:n
        }
      })
    }
  },
  components: {
    Headers,
  }
};
</script>

<style lang="scss" scoped>
.announcement {
  color: #666;
  width: 100%;
  //   padding: 0 13px;
  .content {
    display: flex;
    align-items: center;
    min-height: 75px;
    padding: 0 14px;
    // background: #ffffff;
    border-radius: 10px;
    // @apply text-primary;
    margin-bottom: 10px;
    .left {
      width: 45px;
      height: 45px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: 9px;
      height: 15px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .middle {
      width: 241px;
      margin: 0 0 0 18px;
      .box1 {
        height: 15px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 15px;

      }
      .box2 {
        min-height: 18px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        opacity: 0.57;
      }
      .box2 {
        height: 18px;
        margin: 4px 0;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 18px;
        opacity: 0.57;
      }
      .box3 {
        height: 16px;
        font-size: 9px;
        font-family: PingFang SC;
        font-weight: 500;
        // @apply text-primary;
        line-height: 16px;
      }
    }
  }
}
</style>
