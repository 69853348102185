<template>
  <div id="app">
    <div class="h" :class="{'tabbar-none':!$route.meta.tabbar}">
      <keep-alive >
        <router-view v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"/>
      <router-view class="flex-none" name="tabbar"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    const lg = sessionStorage.getItem('Language')
    if (lg) {
      this.$i18n.locale = lg
    }
  },
}
</script>
<style lang="scss">
#app{
  height: 100%;
}
.h{
  height: calc(100% - 50px - env(safe-area-inset-bottom));
  &.tabbar-none{
    height: 100%;
  }
}
</style>
