module.exports = {
    welcome:'Welcome To',
    tzsy:'Investment income',
    tq:'Redemption',
    cjye:'Balance of exit',
    rhl:'Daily income rate',
    yqhy:'Invite friends',
    fh:'reward',
    over:'Forging is over',
    invite:'Binding the invitation relationship',
    gobind:'Binding',
    coinList:'Coin List',
    inputInvite:'Enter the code',
    jhzh:'Activate account',
    jhmyw:'Incorrect activation code',
    jhcg:'Congratulations on opening the door of wealth',
    zdl:'Got it',
    ljjc:'Activate now',
    node:'Node',
    checkDay:'Contract cycle',
    bc:'Receive reward',
    v1banlace:'V1Balance',
    userHashPwoer:'user',
    teamHashPwoer:'team',
    totalHashPwoer:'total',
    head: {
        djs:'Unicorn node',
        updatesuccess:'update success',
        update:'update',
        audit: "audit report ",
        allRedemption: "All the redemption",
        activeRelationship: "binding the invitation relationship",
        activeAccount: "activate an account",
        approve: "approve",
        address: "address",
        aa1: "My node",
        aa2: "select node",
        aa3: "balance/USDT",
        aa4: "select",
        aaa5: "destroy",
        aa5: "need to destroy",
        aa6: "VIP progress",
        aa7: "approve",
        aa8: "upgrade",
        aa9: "explain",
        aa10: "VIP is valid for life",
        aa11: "Subsequent upgrades are required to make up the difference at current prices",
        aa12: "The actual amount of UNC pledged is subject to the real-time price on the chain at the time of transaction confirmation",
        aa13: "The UNC pledge quantity shown is the reference quantity calculated based on the current price",
        acquire: "acquire",
        balance: "balance",
        amounts : "amounts",
        currencySearch: " enter the currency",
        contracSource: "contract source",
        voting: "Voting",
        confirm: "confirm",
        cancel: "cancel",
        copySuccess: "copy success",
        copyFail: "copy fail",
        copyLink: "copy the invitation link",
        enterInviter: "Please enter the inviter",
        hashPower: "hash power",
        hash: "Hash",
        overtime: "over times",
        overout:'out amount',
        inputAmount: "input amount",
        inviterAddress: "inviter address",
        IPHBalance : "UNC balance",
        IPHPrice: "UNC price",
        max: "MAX",
        mycommunity: "community",
        myLevel: "my node",
        NFTBox: "NFT Box",
        NFTTrading: "NFT Trading",
        noInviter: "No inviter",
        noInviterFail: "No invitee, registration failed",
        notOpen: "not opened yet",
        announcement: "announcement",
        pledged: "pledged",
        pledgeLoan: "pledge loan",
        pledgeRate: "Pledge proportion",
        personal: "personal",
        scanOrCopy: "Scan or copy the link",
        stake: "stake",
        unstake: "unstake",
        team: "team",
        rewards: "rewards",
        get: "get",
        willGetPower: "You're going to get the power",
        price: "price",
        language: "language",
        referral: "relation",
        route1:'stake',
        route2:'announcement',
        route3:'community',
        route4:'buy Node',
        route5:'language',
        route6:'redeem',
        route7:'share',
        route8:'unlock',
        shangye:"previous page",
        xiaye:"next page",
        weizhuce:"unregistered",
        sq:"Please select the level",
        sq1:"Please confirm the authorization.",
        sl:"Please enter quantity",
        pool:'LP-Pool',
        day:'Day',
        vip1:'Community node',
        vip2:'Alliance node',
        vip3:'Super node',
        vip4:'Genesis node',
        dev:'Coming online soon, please look forward to it',
        deposit:'deposit',
        withdraw:'withdraw',
        reward:'reward',
        hole:'hole',
        polymerization:'polymerization',
        meta:'Metaverse',
        chainQuotient:'ChainBusiness',
    },
    d:{
        title:'transaction data',
        des:'Buy / Sell / Transfer',
        h1:"Today's price",
        h2:'recommended buying slip point',
        h3:'suggested selling sliding point',
        h4:'regular purchase transaction fee',
        h5:'special purchase transaction fee',
        h6:'regular selling transaction fee',
        h7:'special selling transaction fee',
        h8:'transfer transaction fee',
        danger:'Reminder: a single wallet can hold up to 5 UNC, and a single wallet can buy or sell up to 5 UNC'
    },
    home:'home',
    nft:'NFT',
    market:'market',
    data:'data',
    user:'user',
    forging:'forging',
    release:'publish',
    csnft:'Genesis NFT',
    lmnft:'Alliance NFT',
    success:'Success',
    forgingsuccess:'Congratulations on your successful forging!',
    mynft:'My NFT',
    HashRate:'Nft HashRate',
}
