import iphabi from './iph.json'
import iphpoolabi1 from './iphpool.json'
import iphpoolabi2 from './pool2.json'
import iphInvitationabi from './iphInvitationabi.json'
import lppool from './LPPool.json'
import erc20 from "./erc20.json"
import unicorn2box_abi from './Unicorn2Box.json'
import unc_abi from './UNC.json'
import UnicornCoreToken_abi from './UnicornCore.json'
import NodeBy_abi from './NovaUncNode.json'
import pool1reward_abi from './pool1reward.json'
import igetpool1reward_abi from './Igetpool1reward.json'
import MTC4Pool2_abi from './MTC4Pool2.json'
import LPrewards_abi from './lprewards.json'
import NFT1Rewards_abi from './NFT1reward.json'
import NFT2Rewards_abi from './NFT2reward.json'
import NFT2RewardsUpdate_abi from './NFT2RewardInterface.json'
import v1balanceCell_abi from './v1balanceCell.json'
import v1balanceSend_abi from './v1balanceSend.json'
import PIPOOL3_abi from './PI_Pool3.json'
import PIPOOL4_abi from './PI_Pool4.json'
import PIPOOL5_abi from './metadao-pool5.json'
export default {
    data() {
        return {
            global: {
                web3: undefined,
                account: undefined,
            },
            apiUrl:'http://8.219.236.203:8081',
            zhitui: "http://8.219.236.203:8081",
            gonggao: "http://97.74.88.60:7777/yzc-znl-api/api/announcement/list",
            listdata: "http://97.74.88.60:7777/yzc-znl-api/api",
            nftapi:'http://8.219.236.203:8081',
            nfturl:'http://8.219.236.203:8081/#',
            multiCallContract:'0x3acA0af190BB423A27511CDad0Df77928ed377af',
            pool: {
                iph: "0x97D123e2fDA0aB6e3111ab31F1c60A1bd3f0C2F8",
                iphpool1: '0x7E56dAa14971677109Dc3e15988A970F466B91C2',//nft矿池
                iphpool2: '0x989dD4E723256C06155eC0e4d2908e564EbbAA72',//聚合挖矿
                iphpool3: '0x4B924504EeCb3d472d55aa26A770885e91933101',
                iphpool4: '0xD7f1725E2a49cfD9fE5C7127F207e4Dc656EE72F',
                pool5: '0x980Ac03A51D21E351D1D4dc86BD75B19621eB979',
                pi:'0x908Ad23D0de9b579AE75353e7304bEB2DDc53E32',
                STAR:'0xBdE82826A29c3060B112471fFa4A60ECD538d29D',
                pi_pool3:'0x6Bfee0eE6B79582f5282640BF120A3A09a1ea77D',
                pi_pool4:'0xa12aF5D1Eb9D484f0D0B585da12F1a7Df5c13826',
                v1balanceCell: '0x6258CBf8ddDDEDf34A30b178Ab91d8559Cd287d2',
                v1balanceSend: '0xfa1c2c6645Eab6fb6066312F225A2394467e3a44',
                MTC4Pool2:'0x9E326cd6D4CeEE05C23C4b7Ce5d8bfCAfb86815a',
                MTC:'0x70AE0112a5940AF8D93E28BEa900bcE65861127D',
                iphInvitation: '0xa58A9CC985a12d534dB85367C6DA9DD34D90ACE5',
                cakelpunc:'0x2F32E6b18Aa49bdf1aB61FF8976B1EdCB351111B',
                LPPool:'0x79F84103630cc8bCf31964C6d058f4bf9bE4650f',
                USDT: "0x55d398326f99059fF775485246999027B3197955",
                BTC: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
                ETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
                Unicorn2Box: "0xa7dEA3422F2eb944708ED89868038a1Eca66625E",
                //nft代币合约
                UnicornCoreToken:'0x3d5592733d5587C2Dd06D48022ED89fF346Ba2f8',

                TCPUNC_Node:'0x0cC7EFFe20f18e2d2498986B61B525f2c02c9Fc4',
                MTCUNC_Node:'0x3596066C14902053b5d49Fc555265fD3E9d7fda6',
                NOVAUNC_Node:'0xC7a060dB7671D3b27feCA7bBA7c7191Bc694798d',
                USDTUNC_Node:'0x2202ccABc684Cd8976Ea80ad53452212889C14A1',

                //领取补偿合约
                pool1reward:'0x9335D3a2bEA4Ac6bF3EE5934C34B3F8e30DA8B74',
                //获取补偿数据
                igetpool1reward:'0x661C55f95fa47B2462B1C81d0C817719D75215DB',
                LPrewards:'0xd2447c2bB3bd4462771b992258ab0FefE5524014',
                NFT1Rewards:'0xce7C5Eef6E59f529Aa300476830F128ED38681dC',//创世卡牌
                NFT2Rewards:'0xDAD2256377B1ef8B6EF988b63898987F53b096fF',//联盟卡牌
                NFT2RewardsUpdate:'0xDAD2256377B1ef8B6EF988b63898987F53b096fF',//联盟卡牌更新
            },
            // pool: {
            //     iph: "0x2ff31405F589cE517DbC5cA1D3C60772eAD8d05e",
            //     iphpool: '0xf74A8A4c9a2767604dCffb29a7f76582F01Df82A',
            //     iphInvitation: '0x1EEDef49131952a9423a2683252bFac8F9e0d87F',
            //     USDT: "0x55d398326f99059fF775485246999027B3197955",
            //     BTC: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
            //     ETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
            // },
            poolabi: {
                iph_abi: iphabi,
                iphpool_abi1: iphpoolabi1,
                iphpool_abi2: iphpoolabi2,
                iphInvitation_abi: iphInvitationabi,
                erc20abi: erc20,
                lppool_abi:lppool,
                unicorn2box_abi:unicorn2box_abi,
                unc_abi:unc_abi,
                UnicornCoreToken_abi:UnicornCoreToken_abi,
                NodeBy_abi:NodeBy_abi,
                pool1reward_abi:pool1reward_abi,
                igetpool1reward_abi:igetpool1reward_abi,
                MTC4Pool2_abi:MTC4Pool2_abi,
                LPrewards_abi:LPrewards_abi,
                NFT1Rewards_abi:NFT1Rewards_abi,
                NFT2Rewards_abi:NFT2Rewards_abi,
                NFT2RewardsUpdate_abi:NFT2RewardsUpdate_abi,
                v1balanceSend_abi:v1balanceSend_abi,
                v1balanceCell_abi:v1balanceCell_abi,
                PIPOOL3_abi:PIPOOL3_abi,
                PIPOOL4_abi:PIPOOL4_abi,
                pool_abi5:PIPOOL5_abi,
            },
            IPH_contract: undefined,
            IPHPool1_contract: undefined,
            IPHPool2_contract: undefined,
            IPHInvitation_contract: undefined,
            USDT_contract: undefined,
            LP_contract: undefined,
            Unicorn2Box_contract:undefined,
            UNC_contract:undefined,
            UnicornCoreToken_contract:undefined,
            NFTPool_contract:undefined,
            Node_contract:undefined,
            pool1reward_contract:undefined,
            igetpool1reward_contract:undefined,
            MTC4Pool2_contract:undefined,
            LPrewards_contract:undefined,
            NFT1Rewards_contract:undefined,
            NFT2Rewards_contract:undefined,
            NFT2RewardsUpdate_contract:undefined,
            v1balanceSend_contract:undefined,
            v1balanceCell_contract:undefined,
            PIPOOL3_contract:undefined,
            PIPOOL4_contract:undefined,
            POOL5_contract:undefined,
            PI_contract:undefined,
            STAR_contract:undefined,
        }
    },
    created() {
        this.start();
        this.connectMetaMask()
        let self = this
        setTimeout(function chechAuth() {
            if (window.ethereum.selectedAddress === null) setTimeout(chechAuth, 200);
            else self.global.account = window.ethereum.selectedAddress
            console.log(self.global.account, "account")
        }, 200);
    },
    methods: {
        start() {
            this.global.web3 = window.web3 = new this.Web3(window.ethereum);
            // console.log(this.global.web3);
            window.ethereum.autoRefreshOnNetworkChange = false;
            window.ethereum.on("accountsChanged", () => {
                console.log('accountsChanged')
            });
            window.ethereum.on("networkChanged", () => {
                console.log('networkChanged')
            });
        },
        async connectMetaMask() {
            try {
                const newAccounts = await ethereum.request({
                    method: "eth_requestAccounts",
                });
                this.account = newAccounts[0]
            } catch (error) {
                // this.$toast("权限请求已待处理");
            }
        },
    },
}
