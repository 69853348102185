module.exports = {
    welcome:'歡迎來到',
    tzsy:'投資收益',
    tq:'提取',
    cjye:'出局餘額',
    rhl:'日收率',
    yqhy:'邀請好友',
    fh:'分紅',
    over:'鍛造已結束',
    invite:'綁定邀請關係即可啟動',
    gobind:'去綁定',
    coinList:'貨幣列表',
    inputInvite:'請輸入啟動碼',
    jhzh:'啟動帳號',
    jhmyw:'啟動碼有誤',
    jhcg:'恭喜您，開啟財富之門',
    zdl:'我知道了',
    ljjc:'立即啟動',
    node:'節點',
    checkDay:'合約週期',
    bc:'領取獎勵',
    v1banlace:'V1餘額',
    userHashPwoer:'個人算力',
    teamHashPwoer:'團隊算力',
    totalHashPwoer:'總算力',
    head: {
        djs:'獨角獸節點',
        updatesuccess:'更新成功',
        update:'更新',
        audit: "審計報告",
        allRedemption: "全部贖回",
        activeRelationship: "綁定邀請關係即可激活",
        activeAccount: "激活賬號",
        approve: "授權",
        address: "地址",
        aa1: "我的節點",
        aa2: "選擇節點",
        aa3: "餘額/USDT",
        aa4: "請選擇",
        aaa5: "銷燬",
        aa5: "總需銷燬",
        aa6: "VIP提升進度",
        aa7: "授權",
        aa8: "合成",
        aa9: "說明",
        aa10: "VIP有效期爲終身",
        aa11: "需要後續升級,以當前價格彌補差額",
        aa12: "實際質押的UNC數量以交易確認時鏈上實時價格爲準",
        aa13: "顯示的UNC質押數量是根據當前價格計算的參考數量",
        acquire: "獲取",
        balance: "餘額",
        amounts : "金額",
        currencySearch: "請輸入要搜索的幣種",
        contracSource: "合約開源",
        voting: "投票",
        confirm: "確定",
        cancel: "取消",
        copySuccess: "複製成功",
        copyFail: "複製失敗",
        copyLink: "複製邀請鏈接",
        enterInviter: "請輸入邀請人",
        hashPower: "算力值",
        hash: "算力",
        overtime: "出局",
        overout: "出局金額",
        inputAmount: "輸入數量",
        inviterAddress: "邀請地址",
        IPHBalance : "UNC餘額",
        IPHPrice: "UNC當前價格",
        max: "最高",
        mycommunity: "我的社區",
        myLevel: "我的節點",
        NFTBox: "NFT寶盒",
        NFTTrading: "NFT交易",
        noInviter: "無邀請人",
        noInviterFail: "無邀請人,激活失敗",
        notOpen: "暫未開放",
        announcement: "系统公告",
        pledged: "已質押",
        pledgeLoan: "質押借貸",
        pledgeRate: "質押比例",
        personal: "個人權益",
        scanOrCopy: "掃一掃或複製鏈接",
        stake: "質押",
        unstake: "贖回",
        team: "團隊權益",
        rewards: "獎勵算力",
        get: "獲取",
        willGetPower: "你將獲得算力",
        price: "當前價格",
        language: "語言設置",
        referral: "關聯用戶",
        route1:'質押',
        route2:'公告',
        route3:'社區',
        route4:'購買節點',
        route5:'語言',
        route6:'贖回',
        route7:'分享好友',
        route8:'鎖倉',
        shangye:"上一頁",
        xiaye:"下一頁",
        weizhuce:"未激活",
        sq:"請選擇等級",
        sq1:"請確認授權",
        sl:"請輸入數量",
        pool:'LP-權益',
        day:'天',
        vip1:'社區節點',
        vip2:'聯盟節點',
        vip3:'超級節點',
        vip4:'創世節點',
        dev:'即將上線，敬請期待',
        deposit:'鎖倉',
        withdraw:'贖回',
        reward:'奖励',
    },
    d:{
        title:'交易數據',
        des:'買入 / 賣出 / 轉帳',
        h1:'今日價格',
        h2:'當前建議買入滑點',
        h3:'當前建議賣出滑點',
        h4:'當前常規買入交易費',
        h5:'當前特別買入交易費',
        h6:'當前常規賣出交易費',
        h7:'當前特別賣出交易費',
        h8:'當前轉帳交易費',
        danger:'提醒：單個錢包最多持有5枚UNC，單次最多買入或賣出5枚UNC'
    },
    home:'首頁',
    nft:'NFT',
    market:'市場',
    data:'數據',
    user:'用戶',
    forging:'鑄造',
    release:'發行',
    csnft:'創世NFT',
    lmnft:'聯盟NFT',
    success:'成功',
    forgingsuccess:'恭喜您鍛造成功！',
    mynft:'我的NFT',
    HashRate:'NFT鍛造算力',
}
