import Vue from "vue";
import VueRouter from "vue-router";

import community from "../views/community.vue";
import announcement from "../views/announcement.vue";
import TabBar from '@/components/TabBar'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: () => import("../views/new/home"),
      tabbar: TabBar
    },
    meta: {
      keepAlive: true,
      tabbar: true
    },
  },
  {
    path: "/dao",
    name: "Dao",
    components: {
      default: () => import("../views/new/dao"),
      tabbar: TabBar
    },
    meta: {
      keepAlive: true,
      tabbar: true
    },
  },
  {
    path: "/nft",
    name: "NFT",
    components: {
      default: () => import("../views/new/nft"),
      tabbar: TabBar
    },
    meta: {
      keepAlive: true,
      tabbar: true
    },
  },
  {
    path: "/lp",
    name: "LP",
    components: {
      default: () => import("../views/new/lp"),
      tabbar: TabBar
    },
    meta: {
      keepAlive: true,
      tabbar: true
    },
  },
  {
    path: "/data",
    name: "Data",
    component: () => import("../views/new/data"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/user",
    name: "User",
    components: {
      default: () => import("../views/new/user"),
      tabbar: TabBar
    },
    meta: {
      keepAlive: false,
      tabbar: true
    },
  },
  {
    path: "/pi",
    name: "Pi",
    components: {
      default: () => import("../views/new/pi"),
      tabbar: TabBar
    },
    meta: {
      keepAlive: true,
      tabbar: true
    },
  },
  {
    path: "/node",
    name: "Node",
    component: () => import("../views/new/node"),
    meta: {
      keepAlive: false
    },
  },
  {
    path: "/stake",
    name: "Stake",
    component: () => import("../views/new/stake"),
    meta: {
      keepAlive: false
    },
  },


  {
    path: "/unstake",
    name: "UNStake",
    component: () => import("../views/new/unstake"),
    meta: {
      keepAlive: false
    },
  },
  {
    path: "/announcement",
    name: "announcement",
    component: announcement,
    meta: {
      title: "head.route2",
      keepAlive: false,
    },
  },

  {
    path: "/gonggao",
    name: "announce",
    component: () => import("../components/gonggao.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/community",
    name: "community",
    component: community,
    meta: {
      title: "head.route3",
      keepAlive: false,
    },
  },

  {
    path: "/language",
    name: "language",
    component: () => import("../views/Language.vue"),
    meta: {
      title: "head.route5",
      keepAlive: false,
    },
  },
  {
    path: "/fenxiang",
    name: "fenxiang",
    component: () => import("../views/fenxiang.vue"),
    meta: {
      title: "head.route7",
      keepAlive: false,
    },
  },
  {
    path: "/pdfjs",
    name: "pdfjs",
    component: () => import("../components/pdfjs.vue"),
    meta: {
      title: "head.route7",
      keepAlive: true,
    },
  },
  {
    path: "/unlock",
    name: "unlock",
    component: () => import("../views/unlock.vue"),
    meta: {
      title: "head.pool",
      keepAlive: false,
    },
  },
  {
    path: "/lucky-wheel",
    name: "LuckyWheel",
    component: () => import("../views/LuckyWheel.vue"),
    meta: {
      // title: "head.pool",
      keepAlive: false,
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
