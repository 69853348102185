module.exports = {
    welcome:'欢迎来到',
    tzsy:'投资收益',
    tq:'提取',
    cjye:'出局余额',
    rhl:'日收率',
    yqhy:'邀请好友',
    fh:'分红',
    over:'锻造已结束',
    invite:'绑定邀请关系即可激活',
    gobind:'去绑定',
    coinList:'货币列表',
    inputInvite:'请输入激活码',
    jhzh:'激活账号',
    jhmyw:'激活码有误',
    jhcg:'恭喜您，开启财富之门',
    zdl:'我知道了',
    ljjc:'立即激活',
    node:'节点',
    checkDay:'合约周期',
    bc:'领取奖励',
    v1banlace:'V1余额',
    userHashPwoer:'个人算力',
    teamHashPwoer:'团队算力',
    totalHashPwoer:'团队算力',
    head: {
        djs:'独角兽节点',
        updatesuccess:'更新成功',
        update:'更新',
        audit: "审计报告",
        allRedemption: "全部赎回",
        activeRelationship: "绑定邀请关系即可激活",
        activeAccount: "激活账号",
        approve: "授权",
        address: "地址",
        aa1: "我的节点",
        aa2: "选择节点",
        aa3: "余额/USDT",
        aa4: "请选择",
        aaa5: "销毁",
        aa5: "总需销毁",
        aa6: "VIP提升进度",
        aa7: "授权",
        aa8: "合成",
        aa9: "说明",
        aa10: "VIP有效期为终身",
        aa11: "需要后续升级,以当前价格弥补差额",
        aa12: "实际质押的UNC数量以交易确认时链上实时价格为准",
        aa13: "显示的UNC质押数量是根据当前价格计算的参考数量",
        acquire: "获取",
        balance: "余额",
        amounts : "金额",
        currencySearch: "请输入要搜索的币种",
        contracSource: "合约开源",
        voting: "投票",
        confirm: "确定",
        cancel: "取消",
        copySuccess: "复制成功",
        copyFail: "复制失败",
        copyLink: "复制邀请链接",
        enterInviter: "请输入邀请人",
        hashPower: "算力值",
        hash: "算力",
        overtime: "出局",
        overout:'出局金额',
        inputAmount: "输入数量",
        inviterAddress: "邀请地址",
        IPHBalance : "UNC余额",
        IPHPrice: "UNC价格",
        max: "最高",
        mycommunity: "我的社区",
        myLevel: "我的节点",
        NFTBox: "NFT宝盒",
        NFTTrading: "NFT交易",
        noInviter: "无邀请人",
        noInviterFail: "无邀请人,激活失败",
        notOpen: "暂未开放",
        announcement: "系统公告",
        pledged: "已质押",
        pledgeLoan: "质押借贷",
        pledgeRate: "质押比例",
        personal: "个人权益",
        scanOrCopy: "扫一扫或复制链接",
        stake: "质押",
        unstake: "赎回",
        team: "团队权益",
        rewards: "奖励算力",
        get: "获  取",
        willGetPower: "你将获得算力",
        price: "当前价格",
        language: "语言设置",
        referral: "关联用户",
        route1:'质押',
        route2:'公告',
        route3:'社区',
        route4:'购买节点',
        route5:'语言',
        route6:'赎回',
        route7:'分享',
        route8:'锁仓',
        shangye:"上一页",
        xiaye:"下一页",
        weizhuce:"未激活",
        sq:"请选择等级",
        sq1:"请确认授权",
        sl:"请输入数量",
        pool:'LP权益',
        day:'天',
        vip1:'社区节点',
        vip2:'联盟节点',
        vip3:'超级节点',
        vip4:'创世节点',
        dev:'即将上线，敬请期待',
        deposit:'锁仓',
        withdraw:'赎回',
        reward:'奖励',
        hole:'黑洞',
        polymerization:'聚合交易',
        meta:'元宇宙',
        chainQuotient:'链商'
    },
    d:{
        title:'交易数据',
        des:'买入 / 卖出 / 转账',
        h1:'今日价格',
        h2:'当前建议买入滑点',
        h3:'当前建议卖出滑点',
        h4:'当前常规买入交易费',
        h5:'当前特别买入交易费',
        h6:'当前常规卖出交易费',
        h7:'当前特别卖出交易费',
        h8:'当前转账交易费',
        danger:'提醒：单个钱包最多持有5枚UNC，单次最多买入或卖出5枚UNC'
    },
    home:'首页',
    nft:'NFT',
    market:'市场',
    data:'数据',
    user:'我的',
    forging:'锻造',
    release:'发行',
    csnft:'创世NFT',
    lmnft:'联盟NFT',
    success:'成功',
    forgingsuccess:'恭喜您锻造成功！',
    mynft:'我的NFT',
    HashRate:'Nft锻造算力',
}
