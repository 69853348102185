<template>
  <div class="tabh">
    <div class="fixed bottom-0 left-0 w-full h flex bg-bg items-center border-t border-[#363d4c] border-solid z-100">
      <div
        class="flex-1 flex flex-col items-center justify-center text-[#646566] h-full relative"
        v-for="(item, index) in tabbar"
        :key="index"
        @click="onChangeTab(item, index)"
        :class="{ active: index == active }"
      >
        <div class="h-28px leading-28px">
          <img :src="require(`@/assets/new/${item.icon}${index == active?'_Active':''}.png`)" class="h-28px w-28px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
  data() {
    return {
      active: 0,
      tabbar: [
        {
          name: this.$t('home'),
          path: '/',
          pathName: 'Home',
          icon: 'home'
        },
        {
          name: this.$t('dao'),
          path: '/dao',
          pathName: 'Dao',
          icon: 'dao'
        },
        // {
        //   name: 'Pool3 & 4',
        //   path: '/pi',
        //   pathName: 'Pi',
        //   icon: 'PI',
        // },
        // {
        //   name: this.$t('nft'),
        //   path: '/nft',
        //   pathName: 'NFT',
        //   icon: 'wallet',
        // },
        // {
        //   name: this.$t('data'),
        //   path: '/lp',
        //   pathName: 'LP',
        //   icon: 'data',
        // },
        {
          name: this.$t('user'),
          path: '/user',
          pathName: 'User',
          icon: 'persona',
        },
        
      ],
    }
  },
  created() {
    const toName = this.$route.name
    this.setActive(toName)
  },
  methods: {
    onChangeTab(item, index) {
      if (!item.path || this.active == index) return
      this.active = index
      this.$router.push(item.path)
    },
    changeActive({ name }) {
      this.setActive(name)
    },
    setActive(name) {
      this.tabbar.forEach((tab, i) => {
        if (tab.pathName == name) {
          this.active = i
          return false
        }
      })
    },
  },
  watch: {
    $route: 'changeActive',
  },
}
</script>
<style lang="scss" scoped>
.tabh{
  height: calc(50px + env(safe-area-inset-bottom));
}
.h {
  height:50px;
  box-sizing: content-box;
  padding-bottom: env(safe-area-inset-bottom);
}
.active{
  // &::after{
  //   content: '';
  //   @apply absolute top-42px w-4px h-4px bg-gradient-to-tr to-primary1 from-primary2 rounded-full left-1/2 transform -translate-x-1/2;
  // }
}
</style>
