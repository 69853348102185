import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vant from 'vant';
import 'vant/lib/index.css';

import Web3 from 'web3'
import VueClipboard from 'vue-clipboard2'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import '@/assets/css/index.scss'

import * as filters from '@/utils/filters'
import 'windi.css'

Vue.use(Vant);

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.use(VueI18n)
Vue.prototype.$axios = axios
Vue.prototype.Web3 = Web3
Vue.use(VueClipboard)

Vue.config.productionTip = false;


const i18n = new VueI18n({
  locale: 'zh', // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'en': require('./i18n/en'), //英文
    'zh': require('./i18n/zh'), //中文
    'zhf': require('./i18n/zhf'), //繁体
  }
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");